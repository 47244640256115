
// import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js'
// import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import * as THREE from 'three'

class Avatar {
    constructor(avatar, editor) {
        this.avatar = avatar
        this.editor = editor
        this.objects = []
        this.loader = new GLTFLoader()
        this.group = new THREE.Group()

        this.load()
    }

    async load() {
        return new Promise(async (resolve, reject) => {
            const promises = this.avatar.map(async (el) => {
                return this._loadHandler(el)
            })

            await Promise.all(promises)

            this.objects.forEach((el) => {
                this.group.add(el)
            })
    
            this.editor.addObject(this.group)
            resolve(this.objects)
        })
    }

    async _loadHandler(el) {
        return new Promise(async (resolve, reject) => {
            await this.loader.load(el, async (gltf) => {
                const object = gltf.scene

                this.objects.push(object)
                resolve()
            })
        })
    }

    rotate(deg) {
        this.group.rotateY(Math.PI / 180 * deg)
    }

    show() {
        this.group.visible = true
    }

    hide() {
        this.group.visible = false
    }
}

export default Avatar
