import './style.css'
import Editor from './scripts/editor'
import ItemBuilder from './scripts/itemBuilder'
import itemsConfig from './scripts/itemsConfig'

import AppDebug from './debug'

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js').then(function (registration) {
    console.log('Service Worker Registered:', registration.scope)
  }).catch(function (error) {
    console.log('Service Worker Registration Err:', error)
  })
}

document.getElementById('loader-wrapper')

function showLoader() {
  console.log(`@message.loading.true`)
  document.getElementById('loader-wrapper').style.display = 'flex'
}
function hideLoader() {
  document.getElementById('loader-wrapper').style.display = 'none'
  console.log(`@message.loading.false`)
}

const editor = new Editor()

window.editor = editor

const builder = new ItemBuilder(editor)

function main() {
  editor.init()
  editor.sizeTable(320, 460);

  window.hideControls()

  hideLoader();
  // loadItemDebug();
  // let name = "hoodie_regular_male";
  //  window.buildItem({
  //   "name": name,
  //   "data": {
  //     "parts": {
  //       "front": {
  //         "prints": [
  //           {
  //             "url": "https://s3.eu-central-1.amazonaws.com/customitem.app/customers/8878e673-8122-4bb7-bd73-20111b2cdb0a/items/e91cfc57-5313-4916-abde-a3d23c67650d/c239e954-9a5f-4b6d-90db-8cf16e97ba2b",
  //             "id": 10,
  //             "pos": {
  //               "x": -100,
  //               "y": -100
  //             },
  //             "rotation": 45,
  //             "scale": 1
  //           }
  //         ]
  //       }
  //     }
  //   }
  // })
}

async function loadItemDebug() {
  // await window.loadHDRI('assets/hdri/victoria_sunset_2k.hdr');
  let name = "hoodie_regular_male";
  await window.buildItem({
    "name": name,
    "data": {
      "parts": {
        "front": {
          "prints": [
            {
              "url": "https://s3.eu-central-1.amazonaws.com/customitem.app/customers/8878e673-8122-4bb7-bd73-20111b2cdb0a/items/e91cfc57-5313-4916-abde-a3d23c67650d/c239e954-9a5f-4b6d-90db-8cf16e97ba2b",
              "id": 10,
              "pos": {
                "x": -100,
                "y": -100
              },
              "rotation": 45,
              "scale": 1
            }
          ]
        }
      }
    }
  })

  await window.setActivePart("front")
  await window.setActivePrint(10)
  await window.showControls()

  let png = "https://upload.wikimedia.org/wikipedia/commons/3/38/ThaiCucDo.svg"
  png = "https://upload.wikimedia.org/wikipedia/commons/e/ec/Kvadrato.svg"

  let time = 500;
  for (let i = 0; i < 6; i++) {

    let part = "front";

    if (i == 1) {
      part = "back";
    }

    if (i == 2) {

      part = "sleeve_left";
    }
    if (i == 3) {
      part = "sleeve_right";
    }


    if (i == 4) {
      part = "colar";
    }
    if (i == 1) {
      break;
    }

    console.log(part)

    let initTime = 2 * time + time * i * 7;

    for (let j = 0; j < 6; j++) {
      setTimeout(() => {
        window.setActivePart(part)
        window.addPrint(part, png, j)
        window.setActivePrint(j)
        window.showControls()
        window.updateScale(-0.15 * j)
      }, initTime + time + time * j)
    }

    setTimeout(() => {
      window.getCurrentState()
      window.updateScale(0)
      window.setActivePrint(3)


    }, initTime + time * 8)
  }
}

window.start = async function (width, height) {
  console.log("[3D-Editor] start")

  return new Promise(async (resolve, reject) => {
    editor.updateSize()

    editor.sizeTable(width, height);

    resolve()
  })
}

window.updateSize = async function () {
  console.log("[3D-Editor] update size")
  editor.updateSize()
}

window.buildItem = async function (data) {
  console.log("[3D-Editor] buildItem")

  return new Promise(async (resolve, reject) => {
    showLoader();
    await builder.build(data)
    await editor.setCurrentPrints()
    // await editor.forceRerender()
    hideLoader();
    await window.getCurrentState();
    resolve()
  })
}

window.updateItem = async function (data) {
  console.log("[3D-Editor] updateItem")

  return new Promise(async (resolve, reject) => {
    await editor.updateItem(data)
    await editor.forceRerender()
    window.getCurrentState()

    resolve()
  })
}
// name
window.setActivePart = async function (data) {
  console.log("[3D-Editor] setActivePart")

  return new Promise(async (resolve, reject) => {
    editor.setActivePart(editor.activeItem.objects.find(item => item.name == data))
    window.getCurrentState()

    resolve()
  })
}
// id
window.setActivePrint = async function (data) {
  console.log("[3D-Editor] setActivePrint")

  return new Promise(async (resolve, reject) => {
    editor.setActivePrint(data)
    window.getCurrentState()

    resolve()
  })
}

window.updatePrintLayer = async function (part, id, z) {
  console.log("[3D-Editor] updatePrintLayer")

  return new Promise(async (resolve, reject) => {
    editor.updatePrintLayer(part, id, z)
    window.getCurrentState()

    resolve()
  })
}

window.updateScale = async function (scale) {
  console.log("[3D-Editor] updateScale")

  return new Promise(async (resolve, reject) => {
    editor.updateScale(scale)
    window.getCurrentState()

    resolve()
  })
}


window.addPrint = async function (part, url, id) {
  console.log("[3D-Editor] addPrint")

  return new Promise(async (resolve, reject) => {
    showLoader();
    if (!editor.activePart) {
      await window.setActivePart(part)
    }
    await editor.addPrint(part, url, id)
    hideLoader();
    window.getCurrentState()

    resolve()
  })
}

window.deletePrint = async function (part, id) {
  console.log("[3D-Editor] deletePrint")

  return new Promise(async (resolve, reject) => {
    showLoader();
    await editor.deletePrint(part, id)
    await editor.forceRerender(part)
    hideLoader();
    window.getCurrentState()

    resolve()
  })
}

window.loadHDRI = async function (aPath) {
  try {
    console.log("[3D-Editor] loadHDRI path " + aPath)
    editor.loadHDRI(aPath)
  }
  catch (error) {
    console.error(error);
  }
}

window.showLoader = async function () {
  console.log("[3D-Editor] showLoader")
  showLoader()
}

window.hideLoader = async function () {
  console.log("[3D-Editor] hideLoader")
  hideLoader()
}

window.rotateItem = async function (deg) {
  console.log("[3D-Editor] rotateItem")
  editor.rotateItem(deg)
}

window.showControls = async function () {
  console.log("[3D-Editor] showControls")
  editor.showControls()
}

window.hideControls = async function () {
  console.log("[3D-Editor] hideControls")
  editor.hideControls()
}

window.getCurrentState = async function () {
  console.log("[3D-Editor] getCurrentState")
  editor.getCurrentState()
}

window.showItem = async function () {
  console.log("[3D-Editor] showItem")
  editor.showItem()
  window.getCurrentState()
}

window.hideItem = async function () {
  console.log("[3D-Editor] hideItem")
  editor.hideItem()
}

window.showExtra = async function (id) {
  console.log("[3D-Editor] showExtra")
  editor.showExtra()
  window.getCurrentState()
}

window.hideExtra = async function (id) {
  console.log("[3D-Editor] hideExtra")
  editor.hideExtra()
}

window.showPackage = async function (id) {
  console.log("[3D-Editor] showPackage")
  editor.showPackage()
  window.getCurrentState()
}

window.hidePackage = async function (id) {
  console.log("[3D-Editor] hidePackage")
  editor.hidePackage()
}

window.buildPackage = async function (data) {
  console.log("[3D-Editor] buildPackage")

  return new Promise(async (resolve, reject) => {
    showLoader();
    await builder.buildPackage(data)
    // await editor.setCurrentPrints()
    // await editor.forceRerender()
    hideLoader();
    window.getCurrentState()

    resolve()
  })
}

window.updatePackage = async function (data) {
  console.log("[3D-Editor] updatePackage")

  return new Promise(async (resolve, reject) => {
    await editor.updateItem(data)
    await editor.forceRerenderPackage(data)

    resolve()
  })
}

window.buildExtra = async function (data) {
  console.log("[3D-Editor] buildExtra")

  return new Promise(async (resolve, reject) => {
    showLoader();
    await builder.buildExtra(data)
    // await editor.setCurrentPrints()
    // await editor.forceRerender()
    hideLoader();
    window.getCurrentState()

    resolve()
  })
}

window.updateExtra = async function (data) {
  console.log("[3D-Editor] updateExtra")

  return new Promise(async (resolve, reject) => {
    await editor.updateItem(data)
    await editor.forceRerenderExtra(data)
    window.getCurrentState()

    resolve()
  })
}

main()

// window.startDebug = () => {
//   const debug = new AppDebug()
// }

// window.startDebug()
