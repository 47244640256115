
import Item from './item'
import ItemOBJ from './itemOBJ'
import itemsConfig from './itemsConfig'
import Avatar from './avatar'
import * as THREE from 'three'

class ItemBuilder {
  constructor(editor) {
    this.editor = editor

    this.tShortRegularMale = new Item({
      name: "tshirt_regular_male",
      editor: this.editor,
      config: itemsConfig.tshirt_regular_male.config,
      pos: itemsConfig.tshirt_regular_male.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.tshirt_regular_male.cameraPos[0], 
        itemsConfig.tshirt_regular_male.cameraPos[1], 
        itemsConfig.tshirt_regular_male.cameraPos[2]
      )
    })

    this.tShortRegularFemale = new Item({
      name: "tshirt_regular_female",
      editor: this.editor,
      config: itemsConfig.tshirt_regular_female.config,
      pos: itemsConfig.tshirt_regular_female.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.tshirt_regular_female.cameraPos[0], 
        itemsConfig.tshirt_regular_female.cameraPos[1], 
        itemsConfig.tshirt_regular_female.cameraPos[2]
      )
    })

    this.tShortRelaxedFemale = new Item({
      name: "tshirt_relaxed_female",
      editor: this.editor,
      config: itemsConfig.tshirt_relaxed_female.config,
      pos: itemsConfig.tshirt_relaxed_female.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.tshirt_relaxed_female.cameraPos[0], 
        itemsConfig.tshirt_relaxed_female.cameraPos[1], 
        itemsConfig.tshirt_relaxed_female.cameraPos[2]
      )
    })

    this.tShortRelaxedMale = new Item({
      name: "tshirt_relaxed_male",
      editor: this.editor,
      config: itemsConfig.tshirt_relaxed_male.config,
      pos: itemsConfig.tshirt_relaxed_male.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.tshirt_relaxed_male.cameraPos[0], 
        itemsConfig.tshirt_relaxed_male.cameraPos[1], 
        itemsConfig.tshirt_relaxed_male.cameraPos[2]
      )
    })

    this.hoodieRegularMale = new Item({
      name: "hoodie_regular_male",
      editor: this.editor,
      config: itemsConfig.hoodie_regular_male.config,
      pos: itemsConfig.hoodie_regular_male.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.hoodie_regular_male.cameraPos[0], 
        itemsConfig.hoodie_regular_male.cameraPos[1], 
        itemsConfig.hoodie_regular_male.cameraPos[2]
      )
    })

    this.hoodieRegularFemale = new Item({
      name: "hoodie_regular_female",
      editor: this.editor,
      config: itemsConfig.hoodie_regular_female.config,
      pos: itemsConfig.hoodie_regular_female.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.hoodie_regular_female.cameraPos[0], 
        itemsConfig.hoodie_regular_female.cameraPos[1], 
        itemsConfig.hoodie_regular_female.cameraPos[2]
      )
    })

    this.hoodieRelaxedMale = new Item({
      name: "hoodie_relaxed_male",
      editor: this.editor,
      config: itemsConfig.hoodie_relaxed_male.config,
      pos: itemsConfig.hoodie_relaxed_male.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.hoodie_relaxed_male.cameraPos[0], 
        itemsConfig.hoodie_relaxed_male.cameraPos[1], 
        itemsConfig.hoodie_relaxed_male.cameraPos[2]
      )
    })

    this.hoodieRelaxedFemale = new Item({
      name: "hoodie_relaxed_female",
      editor: this.editor,
      config: itemsConfig.hoodie_relaxed_female.config,
      pos: itemsConfig.hoodie_relaxed_female.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.hoodie_relaxed_female.cameraPos[0], 
        itemsConfig.hoodie_relaxed_female.cameraPos[1], 
        itemsConfig.hoodie_relaxed_female.cameraPos[2]
      )
    })

    this.pantsRegularMale = new Item({
      name: "pants_regular_male",
      editor: this.editor,
      config: itemsConfig.pants_regular_male.config,
      pos: itemsConfig.pants_regular_male.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.pants_regular_male.cameraPos[0], 
        itemsConfig.pants_regular_male.cameraPos[1], 
        itemsConfig.pants_regular_male.cameraPos[2]
      )
    })

    this.pantsRegularFemale = new Item({
      name: "pants_regular_female",
      editor: this.editor,
      config: itemsConfig.pants_regular_female.config,
      pos: itemsConfig.pants_regular_female.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.pants_regular_female.cameraPos[0], 
        itemsConfig.pants_regular_female.cameraPos[1], 
        itemsConfig.pants_regular_female.cameraPos[2]
      )
    })

    this.pantsRelaxedMale = new Item({
      name: "pants_relaxed_male",
      editor: this.editor,
      config: itemsConfig.pants_relaxed_male.config,
      pos: itemsConfig.pants_relaxed_male.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.pants_relaxed_male.cameraPos[0], 
        itemsConfig.pants_relaxed_male.cameraPos[1], 
        itemsConfig.pants_relaxed_male.cameraPos[2]
      )
    })

    this.pantsRelaxedFemale = new Item({
      name: "pants_relaxed_female",
      editor: this.editor,
      config: itemsConfig.pants_relaxed_female.config,
      pos: itemsConfig.pants_relaxed_female.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.pants_relaxed_female.cameraPos[0], 
        itemsConfig.pants_relaxed_female.cameraPos[1], 
        itemsConfig.pants_relaxed_female.cameraPos[2]
      )
    })

    this.shortsRegularMale = new Item({
      name: "shorts_regular_male",
      editor: this.editor,
      config: itemsConfig.shorts_regular_male.config,
      pos: itemsConfig.shorts_regular_male.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.shorts_regular_male.cameraPos[0], 
        itemsConfig.shorts_regular_male.cameraPos[1], 
        itemsConfig.shorts_regular_male.cameraPos[2]
      )
    })

    this.shortsRegularFemale = new Item({
      name: "shorts_regular_female",
      editor: this.editor,
      config: itemsConfig.shorts_regular_female.config,
      pos: itemsConfig.shorts_regular_female.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.shorts_regular_female.cameraPos[0], 
        itemsConfig.shorts_regular_female.cameraPos[1], 
        itemsConfig.shorts_regular_female.cameraPos[2]
      )
    })

    this.shortsRelaxedMale = new Item({
      name: "shorts_relaxed_male",
      editor: this.editor,
      config: itemsConfig.shorts_relaxed_male.config,
      pos: itemsConfig.shorts_relaxed_male.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.shorts_relaxed_male.cameraPos[0], 
        itemsConfig.shorts_relaxed_male.cameraPos[1], 
        itemsConfig.shorts_relaxed_male.cameraPos[2]
      )
    })

    this.shortsRelaxedFemale = new Item({
      name: "shorts_relaxed_female",
      editor: this.editor,
      config: itemsConfig.shorts_relaxed_female.config,
      pos: itemsConfig.shorts_relaxed_female.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.shorts_relaxed_female.cameraPos[0], 
        itemsConfig.shorts_relaxed_female.cameraPos[1], 
        itemsConfig.shorts_relaxed_female.cameraPos[2]
      )
    })

    this.extraHangingLabel = new Item({
      name: "extra_hanging_label",
      editor: this.editor,
      config: itemsConfig.extra_hanging_label.config,
      pos: itemsConfig.extra_hanging_label.pos,
      scale: itemsConfig.extra_hanging_label.scale,
      cameraPos: new THREE.Vector3(
        itemsConfig.extra_hanging_label.cameraPos[0], 
        itemsConfig.extra_hanging_label.cameraPos[1], 
        itemsConfig.extra_hanging_label.cameraPos[2]
      )
    })

    this.designedBoxHoodie = new Item({
      name: "designed_box_hoodie",
      editor: this.editor,
      config: itemsConfig.designed_box_hoodie.config,
      pos: itemsConfig.designed_box_hoodie.pos,
      scale: itemsConfig.designed_box_hoodie.scale,
      cameraPos: new THREE.Vector3(
        itemsConfig.designed_box_hoodie.cameraPos[0], 
        itemsConfig.designed_box_hoodie.cameraPos[1], 
        itemsConfig.designed_box_hoodie.cameraPos[2]
      )
    })

    this.designedBoxPants = new Item({
      name: "designed_box_pants",
      editor: this.editor,
      config: itemsConfig.designed_box_pants.config,
      pos: itemsConfig.designed_box_pants.pos,
      scale: itemsConfig.designed_box_pants.scale,
      cameraPos: new THREE.Vector3(
        itemsConfig.designed_box_pants.cameraPos[0], 
        itemsConfig.designed_box_pants.cameraPos[1], 
        itemsConfig.designed_box_pants.cameraPos[2]
      )
    })

    this.designedBoxShorts = new Item({
      name: "designed_box_shorts",
      editor: this.editor,
      config: itemsConfig.designed_box_shorts.config,
      pos: itemsConfig.designed_box_shorts.pos,
      scale: itemsConfig.designed_box_shorts.scale,
      cameraPos: new THREE.Vector3(
        itemsConfig.designed_box_shorts.cameraPos[0], 
        itemsConfig.designed_box_shorts.cameraPos[1], 
        itemsConfig.designed_box_shorts.cameraPos[2]
      )
    })

    this.designedBoxTshirt = new Item({
      name: "designed_box_tshirt",
      editor: this.editor,
      config: itemsConfig.designed_box_tshirt.config,
      pos: itemsConfig.designed_box_tshirt.pos,
      scale: itemsConfig.designed_box_tshirt.scale,
      cameraPos: new THREE.Vector3(
        itemsConfig.designed_box_tshirt.cameraPos[0], 
        itemsConfig.designed_box_tshirt.cameraPos[1], 
        itemsConfig.designed_box_tshirt.cameraPos[2]
      )
    })

    this.sliderPlasticBagHoodie = new Item({
      name: "slider_plastic_bag_hoodie",
      editor: this.editor,
      config: itemsConfig.slider_plastic_bag_hoodie.config,
      pos: itemsConfig.slider_plastic_bag_hoodie.pos,
      scale: itemsConfig.slider_plastic_bag_hoodie.scale,
      opacity: itemsConfig.slider_plastic_bag_tshirt.opacity,
      rotation: itemsConfig.slider_plastic_bag_tshirt.rotation,
      cameraPos: new THREE.Vector3(
        itemsConfig.slider_plastic_bag_tshirt.cameraPos[0], 
        itemsConfig.slider_plastic_bag_tshirt.cameraPos[1], 
        itemsConfig.slider_plastic_bag_tshirt.cameraPos[2]
      )
    })

    this.sliderPlasticBagPants = new Item({
      name: "slider_plastic_bag_pants",
      editor: this.editor,
      config: itemsConfig.slider_plastic_bag_pants.config,
      pos: itemsConfig.slider_plastic_bag_pants.pos,
      scale: itemsConfig.slider_plastic_bag_pants.scale,
      opacity: itemsConfig.slider_plastic_bag_pants.opacity,
      rotation: itemsConfig.slider_plastic_bag_pants.rotation,
      cameraPos: new THREE.Vector3(
        itemsConfig.slider_plastic_bag_pants.cameraPos[0], 
        itemsConfig.slider_plastic_bag_pants.cameraPos[1], 
        itemsConfig.slider_plastic_bag_pants.cameraPos[2]
      )
    })

    this.sliderPlasticBagShorts = new Item({
      name: "slider_plastic_bag_shorts",
      editor: this.editor,
      config: itemsConfig.slider_plastic_bag_shorts.config,
      pos: itemsConfig.slider_plastic_bag_shorts.pos,
      scale: itemsConfig.slider_plastic_bag_shorts.scale,
      opacity: itemsConfig.slider_plastic_bag_shorts.opacity,
      rotation: itemsConfig.slider_plastic_bag_shorts.rotation,
      cameraPos: new THREE.Vector3(
        itemsConfig.slider_plastic_bag_shorts.cameraPos[0], 
        itemsConfig.slider_plastic_bag_shorts.cameraPos[1], 
        itemsConfig.slider_plastic_bag_shorts.cameraPos[2]
      )
    })

    this.sliderPlasticBagTshirt = new Item({
      name: "slider_plastic_bag_tshirt",
      editor: this.editor,
      config: itemsConfig.slider_plastic_bag_tshirt.config,
      pos: itemsConfig.slider_plastic_bag_tshirt.pos,
      scale: itemsConfig.slider_plastic_bag_tshirt.scale,
      opacity: itemsConfig.slider_plastic_bag_tshirt.opacity,
      rotation: itemsConfig.slider_plastic_bag_tshirt.rotation,
      cameraPos: new THREE.Vector3(
        itemsConfig.slider_plastic_bag_tshirt.cameraPos[0], 
        itemsConfig.slider_plastic_bag_tshirt.cameraPos[1], 
        itemsConfig.slider_plastic_bag_tshirt.cameraPos[2]
      )
    })

    this.shirt_regular_male = new Item({
      name: "shirt_regular_male",
      editor: this.editor,
      config: itemsConfig.shirt_regular_male.config,
      pos: itemsConfig.shirt_regular_male.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.shirt_regular_male.cameraPos[0], 
        itemsConfig.shirt_regular_male.cameraPos[1], 
        itemsConfig.shirt_regular_male.cameraPos[2]
      )
    })

    this.shirt_regular_female = new Item({
      name: "shirt_regular_female",
      editor: this.editor,
      config: itemsConfig.shirt_regular_female.config,
      pos: itemsConfig.shirt_regular_female.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.shirt_regular_female.cameraPos[0], 
        itemsConfig.shirt_regular_female.cameraPos[1], 
        itemsConfig.shirt_regular_female.cameraPos[2]
      )
    })

    this.shirt_relaxed_male = new Item({
      name: "shirt_relaxed_male",
      editor: this.editor,
      config: itemsConfig.shirt_relaxed_male.config,
      pos: itemsConfig.shirt_relaxed_male.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.shirt_relaxed_male.cameraPos[0], 
        itemsConfig.shirt_relaxed_male.cameraPos[1], 
        itemsConfig.shirt_relaxed_male.cameraPos[2]
      )
    })

    this.shirt_relaxed_female = new Item({
      name: "shirt_relaxed_female",
      editor: this.editor,
      config: itemsConfig.shirt_relaxed_female.config,
      pos: itemsConfig.shirt_relaxed_female.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.shirt_relaxed_female.cameraPos[0], 
        itemsConfig.shirt_relaxed_female.cameraPos[1], 
        itemsConfig.shirt_relaxed_female.cameraPos[2]
      )
    })

    this.longsleeve_regular_male = new Item({
      name: "longsleeve_regular_male",
      editor: this.editor,
      config: itemsConfig.longsleeve_regular_male.config,
      pos: itemsConfig.longsleeve_regular_male.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.longsleeve_regular_male.cameraPos[0], 
        itemsConfig.longsleeve_regular_male.cameraPos[1], 
        itemsConfig.longsleeve_regular_male.cameraPos[2]
      )
    })

    this.longsleeve_regular_female = new Item({
      name: "longsleeve_regular_female",
      editor: this.editor,
      config: itemsConfig.longsleeve_regular_female.config,
      pos: itemsConfig.longsleeve_regular_female.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.longsleeve_regular_female.cameraPos[0], 
        itemsConfig.longsleeve_regular_female.cameraPos[1], 
        itemsConfig.longsleeve_regular_female.cameraPos[2]
      )
    })

    this.longsleeve_relaxed_male = new Item({
      name: "longsleeve_relaxed_male",
      editor: this.editor,
      config: itemsConfig.longsleeve_relaxed_male.config,
      pos: itemsConfig.longsleeve_relaxed_male.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.longsleeve_relaxed_male.cameraPos[0], 
        itemsConfig.longsleeve_relaxed_male.cameraPos[1], 
        itemsConfig.longsleeve_relaxed_male.cameraPos[2]
      )
    })

    this.longsleeve_relaxed_female = new Item({
      name: "longsleeve_relaxed_female",
      editor: this.editor,
      config: itemsConfig.longsleeve_relaxed_female.config,
      pos: itemsConfig.longsleeve_relaxed_female.pos,
      cameraPos: new THREE.Vector3(
        itemsConfig.longsleeve_relaxed_female.cameraPos[0], 
        itemsConfig.longsleeve_relaxed_female.cameraPos[1], 
        itemsConfig.longsleeve_relaxed_female.cameraPos[2]
      )
    })

    this.map = {
      hoodie_regular_male: (data) => {
        const conf = itemsConfig.hoodie_regular_male
        this.editor.setActiveItem(this.hoodieRegularMale, 'item')
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.hoodieRegularMale.load(data)
      },
      hoodie_regular_female: (data) => {
        const conf = itemsConfig.hoodie_regular_female

        this.editor.setActiveItem(this.hoodieRegularFemale, 'item')
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.hoodieRegularFemale.load(data)
      },
      hoodie_relaxed_male: (data) => {
        this.editor.setActiveItem(this.hoodieRelaxedMale, 'item')
        const conf = itemsConfig.hoodie_relaxed_male
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.hoodieRelaxedMale.load(data)
      },
      hoodie_relaxed_female: (data) => {
        this.editor.setActiveItem(this.hoodieRelaxedFemale, 'item')
        const conf = itemsConfig.hoodie_relaxed_female
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.hoodieRelaxedFemale.load(data)
      },

      tshirt_regular_male: (data) => {
        this.editor.setActiveItem(this.tShortRegularMale, 'item')
        const conf = itemsConfig.tshirt_regular_male
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.tShortRegularMale.load(data)
      },
      tshirt_regular_female: (data) => {
        this.editor.setActiveItem(this.tShortRegularFemale, 'item')
        const conf = itemsConfig.tshirt_regular_female
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.tShortRegularFemale.load(data)
      },
      tshirt_relaxed_male: (data) => {
        this.editor.setActiveItem(this.tShortRelaxedMale, 'item')
        const conf = itemsConfig.tshirt_relaxed_male
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.tShortRelaxedMale.load(data)
      },
      tshirt_relaxed_female: (data) => {
        this.editor.setActiveItem(this.tShortRelaxedFemale, 'item')
        const conf = itemsConfig.tshirt_relaxed_female
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.tShortRelaxedFemale.load(data)
      },

      pants_regular_male: (data) => {
        this.editor.setActiveItem(this.pantsRegularMale, 'item')
        const conf = itemsConfig.pants_regular_male
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.pantsRegularMale.load(data)
      },
      pants_regular_female: (data) => {
        this.editor.setActiveItem(this.pantsRegularFemale, 'item')
        const conf = itemsConfig.pants_regular_female
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.pantsRegularFemale.load(data)
      },
      pants_relaxed_male: (data) => {
        this.editor.setActiveItem(this.pantsRelaxedMale, 'item')
        const conf = itemsConfig.pants_relaxed_male
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.pantsRelaxedMale.load(data)
      },
      pants_relaxed_female: (data) => {
        this.editor.setActiveItem(this.pantsRelaxedFemale, 'item')
        const conf = itemsConfig.pants_relaxed_female
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.pantsRelaxedFemale.load(data)
      },

      shorts_regular_male: (data) => {
        this.editor.setActiveItem(this.shortsRegularMale, 'item')
        const conf = itemsConfig.shorts_regular_male
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.shortsRegularMale.load(data)
      },
      shorts_regular_female: (data) => {
        this.editor.setActiveItem(this.shortsRegularFemale, 'item')
        const conf = itemsConfig.shorts_regular_female
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.shortsRegularFemale.load(data)
      },
      shorts_relaxed_male: (data) => {
        this.editor.setActiveItem(this.shortsRelaxedMale, 'item')
        const conf = itemsConfig.shorts_relaxed_male
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.shortsRelaxedMale.load(data)
      },
      shorts_relaxed_female: (data) => {
        this.editor.setActiveItem(this.shortsRelaxedFemale, 'item')
        const conf = itemsConfig.shorts_relaxed_female
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.shortsRelaxedFemale.load(data)
      },

      extra_hanging_label: (data) => {
        this.editor.setActiveItem(this.extraHangingLabel, 'extra')
        return this.extraHangingLabel.load(data)
      },

      designed_box_hoodie: (data) => {
        this.editor.setActiveItem(this.designedBoxHoodie, 'package')
        return this.designedBoxHoodie.load(data)
      },
      designed_box_tshirt: (data) => {
        this.editor.setActiveItem(this.designedBoxTshirt, 'package')
        return this.designedBoxTshirt.load(data)
      },
      designed_box_shorts: (data) => {
        this.editor.setActiveItem(this.designedBoxShorts, 'package')
        return this.designedBoxShorts.load(data)
      },
      designed_box_pants: (data) => {
        this.editor.setActiveItem(this.designedBoxPants, 'package')
        return this.designedBoxPants.load(data)
      },

      slider_plastic_bag_hoodie: (data) => {
        this.editor.setActiveItem(this.sliderPlasticBagHoodie, 'package')
        return this.sliderPlasticBagHoodie.load(data)
      },
      slider_plastic_bag_tshirt: (data) => {
        this.editor.setActiveItem(this.sliderPlasticBagTshirt, 'package')
        return this.sliderPlasticBagTshirt.load(data)
      },
      slider_plastic_bag_shorts: (data) => {
        this.editor.setActiveItem(this.sliderPlasticBagShorts, 'package')
        return this.sliderPlasticBagShorts.load(data)
      },
      slider_plastic_bag_pants: (data) => {
        this.editor.setActiveItem(this.sliderPlasticBagPants, 'package')
        return this.sliderPlasticBagPants.load(data)
      },

      shirt_regular_male: (data) => {
        const conf = itemsConfig.shirt_regular_male
        this.editor.setActiveItem(this.shirt_regular_male, 'item')
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.shirt_regular_male.load(data)
      },
      shirt_regular_female: (data) => {
        const conf = itemsConfig.shirt_regular_female

        this.editor.setActiveItem(this.shirt_regular_female, 'item')
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.shirt_regular_female.load(data)
      },
      shirt_relaxed_male: (data) => {
        this.editor.setActiveItem(this.shirt_relaxed_male, 'item')
        const conf = itemsConfig.shirt_relaxed_male
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.shirt_relaxed_male.load(data)
      },
      shirt_relaxed_female: (data) => {
        this.editor.setActiveItem(this.shirt_relaxed_female, 'item')
        const conf = itemsConfig.shirt_relaxed_female
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.shirt_relaxed_female.load(data)
      },

      longsleeve_regular_male: (data) => {
        const conf = itemsConfig.longsleeve_regular_male
        this.editor.setActiveItem(this.longsleeve_regular_male, 'item')
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.longsleeve_regular_male.load(data)
      },
      longsleeve_regular_female: (data) => {
        const conf = itemsConfig.longsleeve_regular_female

        this.editor.setActiveItem(this.longsleeve_regular_female, 'item')
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.longsleeve_regular_female.load(data)
      },
      longsleeve_relaxed_male: (data) => {
        this.editor.setActiveItem(this.longsleeve_relaxed_male, 'item')
        const conf = itemsConfig.longsleeve_relaxed_male
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.longsleeve_relaxed_male.load(data)
      },
      longsleeve_relaxed_female: (data) => {
        this.editor.setActiveItem(this.longsleeve_relaxed_female, 'item')
        const conf = itemsConfig.longsleeve_relaxed_female
        this.editor.setAvatar(new Avatar(conf.avatar, this.editor))
        this.editor.initialCameraPosition = new THREE.Vector3(conf.cameraPos[0], conf.cameraPos[1], conf.cameraPos[2])
        this.editor.setCamera()
        return this.longsleeve_relaxed_female.load(data)
      },
    }
  }

  async build(config) {
    return new Promise(async (resolve, reject) => {
      this.editor.activeItem?.unload()
      this.editor.currentExtras?.forEach(element => {
        element.unload()
      });

      await this.map[config.name](config.data)

      resolve()
    });
  }

  async buildPackage(config) {
    return new Promise(async (resolve, reject) => {
      var containsName = false

      if (this.editor.currentPackages) {
        for (let index = 0; index < this.editor.currentPackages.length; index++) {
          const element = this.editor.currentPackages[index]

          element.hide()

          if (element.name == config.name) {
            containsName = true
          }
        }
      }

      if (!containsName) {
        await this.map[config.name](config.data)
      }

      this.editor.showPackage(config.name)

      resolve()
    });
  }

  // TODO: build extra by id
  async buildExtra(config) {
    return new Promise(async (resolve, reject) => {
      var containsName = false

      if (this.editor.currentExtras) {
        for (let index = 0; index < this.editor.currentExtras.length; index++) {
          const element = this.editor.currentExtras[index]

          element.hide()

          if (element.name == config.name) {
            containsName = true
          }
        }
      }

      if (!containsName) {
        await this.map[config.name](config.data)
      }

      this.editor.showExtra(config.name)

      resolve()
    });
  }
}


export default ItemBuilder
