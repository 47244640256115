
const male = ['assets/avatar_male/Avatar_Male_Body.gltf', 'assets/avatar_male/Avatar_Male_hand.gltf']
const female = ['assets/avatar_female/Avatar_female_body.gltf', 'assets/avatar_female/Avatar_female_hand.gltf']

const itemsConfig = {
    hoodie_regular_male: {
        config: {
            front: {
                path: '/assets/hoodie_regular_male/hoodie_regular_male_front.gltf',
                size: {
                    width: 640,
                    height: 556
                }
            },
            back: {
                path: '/assets/hoodie_regular_male/hoodie_regular_male_back.gltf',
                size: {
                    width: 656.8,
                    height: 556
                }
            },
            bottom_cuff: {
                path: '/assets/hoodie_regular_male/hoodie_regular_male_bottom_cuff.gltf',
                size: {
                    width: 146,
                    height: 1018.23
                }
            },
            cuff_left: {
                path: '/assets/hoodie_regular_male/hoodie_regular_male_cuff_left.gltf',
                size: {
                    width: 146,
                    height: 246.2
                }
            },
            cuff_right: {
                path: '/assets/hoodie_regular_male/hoodie_regular_male_cuff_right.gltf',
                size: {
                    width: 146,
                    height: 246.2
                }
            },
            hood_left_inner: {
                path: '/assets/hoodie_regular_male/hoodie_regular_male_hood_left_inner.gltf',
                size: {
                    width: 433,
                    height: 336.5
                }
            },
            hood_left_outer: {
                path: '/assets/hoodie_regular_male/hoodie_regular_male_hood_left_outer.gltf',
                size: {
                    width: 433,
                    height: 336.5
                }
            },
            hood_right_inner: {
                path: '/assets/hoodie_regular_male/hoodie_regular_male_hood_right_inner.gltf',
                size: {
                    width: 433,
                    height: 336.5
                }
            },
            hood_right_outer: {
                path: '/assets/hoodie_regular_male/hoodie_regular_male_hood_right_outer.gltf',
                size: {
                    width: 433,
                    height: 336.5
                }
            },
            pocket: {
                path: '/assets/hoodie_regular_male/hoodie_regular_male_pocket.gltf',
                size: {
                    width: 277.4,
                    height: 524.2
                }
            },
            sleeve_left: {
                path: '/assets/hoodie_regular_male/hoodie_regular_male_sleeve_left.gltf',
                size: {
                    width: 607.9,
                    height: 464.6
                }
            },
            sleeve_right: {
                path: '/assets/hoodie_regular_male/hoodie_regular_male_sleeve_right.gltf',
                size: {
                    width: 607.9,
                    height: 464.6
                }
            },
            // size_tag: {
            //     path: '/assets/hoodie_regular_male/hoodie_regular_male_size_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // care_tag: {
            //     path: '/assets/hoodie_regular_male/hoodie_regular_male_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // main_label: {
            //     path: '/assets/hoodie_regular_male/hoodie_regular_male_main_label.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 1.35, 0],
        avatar: male,
        defaultColor: '#9b9b9b'
    },

    hoodie_regular_female: {
        config: {
            front: {
                path: '/assets/hoodie_regular_female/hoodie_regular_female_front.gltf',
                size: {
                    width: 634.2,
                    height: 526
                }
            },
            back: {
                path: '/assets/hoodie_regular_female/hoodie_regular_female_back.gltf',
                size: {
                    width: 640.6,
                    height: 536
                }
            },
            bottom_cuff: {
                path: '/assets/hoodie_regular_female/hoodie_regular_female_bottom_cuff.gltf',
                size: {
                    width: 146,
                    height: 953.3
                }
            },
            cuff_left: {
                path: '/assets/hoodie_regular_female/hoodie_regular_female_cuff_left.gltf',
                size: {
                    width: 146,
                    height: 218.4
                }
            },
            cuff_right: {
                path: '/assets/hoodie_regular_female/hoodie_regular_female_cuff_right.gltf',
                size: {
                    width: 146,
                    height: 218.4
                }
            },
            hood_left_inner: {
                path: '/assets/hoodie_regular_female/hoodie_regular_female_hood_left_inner.gltf',
                size: {
                    width: 425.4,
                    height: 334.9
                }
            },
            hood_left_outer: {
                path: '/assets/hoodie_regular_female/hoodie_regular_female_hood_left_outer.gltf',
                size: {
                    width: 425.4,
                    height: 334.9
                }
            },
            hood_right_inner: {
                path: '/assets/hoodie_regular_female/hoodie_regular_female_hood_right_inner.gltf',
                size: {
                    width: 425.4,
                    height: 334.9
                }
            },
            hood_right_outer: {
                path: '/assets/hoodie_regular_female/hoodie_regular_female_hood_right_outer.gltf',
                size: {
                    width: 425.4,
                    height: 334.9
                }
            },
            pocket: {
                path: '/assets/hoodie_regular_female/hoodie_regular_female_pocket.gltf',
                size: {
                    width: 262.6,
                    height: 489.2
                }
            },
            sleeve_left: {
                path: '/assets/hoodie_regular_female/hoodie_regular_female_sleeve_left.gltf',
                size: {
                    width: 567.4,
                    height: 429.4
                }
            },
            sleeve_right: {
                path: '/assets/hoodie_regular_female/hoodie_regular_female_sleeve_right.gltf',
                size: {
                    width: 567.4,
                    height: 429.4
                }
            },
            // size_tag: {
            //     path: '/assets/hoodie_regular_female/hoodie_regular_female_size_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // care_tag: {
            //     path: '/assets/hoodie_regular_female/hoodie_regular_female_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // main_label: {
            //     path: '/assets/hoodie_regular_female/hoodie_regular_female_main_label.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        pos: [0, 0, 0],
        avatar: female,
        cameraPos: [0, 1.23, 0],
    },

    hoodie_relaxed_male: {
        config: {
            front: {
                path: '/assets/hoodie_relaxed_male/hoodie_relaxed_male_front.gltf',
                size: {
                    width: 689.025,
                    height: 626
                }
            },
            back: {
                path: '/assets/hoodie_relaxed_male/hoodie_relaxed_male_back.gltf',
                size: {
                    width: 707.825,
                    height: 626
                }
            },
            bottom_cuff: {
                path: '/assets/hoodie_relaxed_male/hoodie_relaxed_male_bottom_cuff.gltf',
                size: {
                    width: 146,
                    height: 1159.2
                }
            },
            cuff_left: {
                path: '/assets/hoodie_relaxed_male/hoodie_relaxed_male_cuff_left.gltf',
                size: {
                    width: 146,
                    height: 284.05
                }
            },
            cuff_right: {
                path: '/assets/hoodie_relaxed_male/hoodie_relaxed_male_cuff_right.gltf',
                size: {
                    width: 146,
                    height: 284.05
                }
            },
            hood_left_inner: {
                path: '/assets/hoodie_relaxed_male/hoodie_relaxed_male_hood_left_inner.gltf',
                size: {
                    width: 435,
                    height: 336.1
                }
            },
            hood_left_outer: {
                path: '/assets/hoodie_relaxed_male/hoodie_relaxed_male_hood_left_outer.gltf',
                size: {
                    width: 435,
                    height: 336.1
                }
            },
            hood_right_inner: {
                path: '/assets/hoodie_relaxed_male/hoodie_relaxed_male_hood_right_inner.gltf',
                size: {
                    width: 435,
                    height: 336.1
                }
            },
            hood_right_outer: {
                path: '/assets/hoodie_relaxed_male/hoodie_relaxed_male_hood_right_outer.gltf',
                size: {
                    width: 435,
                    height: 336.1
                }
            },
            pocket: {
                path: '/assets/hoodie_relaxed_male/hoodie_relaxed_male_pocket.gltf',
                size: {
                    width: 287.4,
                    height: 581.2
                }
            },
            sleeve_left: {
                path: '/assets/hoodie_relaxed_male/hoodie_relaxed_male_sleeve_left.gltf',
                size: {
                    width: 565.3,
                    height: 549.2
                }
            },
            sleeve_right: {
                path: '/assets/hoodie_relaxed_male/hoodie_relaxed_male_sleeve_right.gltf',
                size: {
                    width: 565.3,
                    height: 549.2
                }
            },
            // size_tag: {
            //     path: '/assets/hoodie_relaxed_male/hoodie_relaxed_male_size_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // care_tag: {
            //     path: '/assets/hoodie_relaxed_male/hoodie_relaxed_male_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // main_label: {
            //     path: '/assets/hoodie_relaxed_male/hoodie_relaxed_male_main_label.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        cameraPos: [0, 1.35, 0],
        avatar: male,
        pos: [0, 0, 0],
    },

    hoodie_relaxed_female: {
        config: {
            front: {
                path: '/assets/hoodie_relaxed_female/hoodie_relaxed_female_front.gltf',
                size: {
                    width: 702.2,
                    height: 631
                }
            },
            back: {
                path: '/assets/hoodie_relaxed_female/hoodie_relaxed_female_back.gltf',
                size: {
                    width: 714.45,
                    height: 651
                }
            },
            bottom_cuff: {
                path: '/assets/hoodie_relaxed_female/hoodie_relaxed_female_bottom_cuff.gltf',
                size: {
                    width: 146,
                    height: 1103.5
                }
            },
            cuff_left: {
                path: '/assets/hoodie_relaxed_female/hoodie_relaxed_female_cuff_left.gltf',
                size: {
                    width: 146,
                    height: 230.6
                }
            },
            cuff_right: {
                path: '/assets/hoodie_relaxed_female/hoodie_relaxed_female_cuff_right.gltf',
                size: {
                    width: 146,
                    height: 230.6
                }
            },
            hood_left_inner: {
                path: '/assets/hoodie_relaxed_female/hoodie_relaxed_female_hood_left_inner.gltf',
                size: {
                    width: 429.025,
                    height: 340.6
                }
            },
            hood_left_outer: {
                path: '/assets/hoodie_relaxed_female/hoodie_relaxed_female_hood_left_outer.gltf',
                size: {
                    width: 429.025,
                    height: 340.6
                }
            },
            hood_right_inner: {
                path: '/assets/hoodie_relaxed_female/hoodie_relaxed_female_hood_right_inner.gltf',
                size: {
                    width: 429.025,
                    height: 340.6
                }
            },
            hood_right_outer: {
                path: '/assets/hoodie_relaxed_female/hoodie_relaxed_female_hood_right_outer.gltf',
                size: {
                    width: 429.025,
                    height: 340.6
                }
            },
            pocket: {
                path: '/assets/hoodie_relaxed_female/hoodie_relaxed_female_pocket.gltf',
                size: {
                    width: 303.45,
                    height: 573.6
                }
            },
            sleeve_left: {
                path: '/assets/hoodie_relaxed_female/hoodie_relaxed_female_sleeve_left.gltf',
                size: {
                    width: 529.6,
                    height: 468.2
                }
            },
            sleeve_right: {
                path: '/assets/hoodie_relaxed_female/hoodie_relaxed_female_sleeve_right.gltf',
                size: {
                    width: 529.6,
                    height: 468.2
                }
            },
            // size_tag: {
            //     path: '/assets/hoodie_relaxed_female/hoodie_relaxed_female_size_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // care_tag: {
            //     path: '/assets/hoodie_relaxed_female/hoodie_relaxed_female_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // main_label: {
            //     path: '/assets/hoodie_relaxed_female/hoodie_relaxed_female_main_label.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        pos: [0, 0, 0],
        avatar: female,
        cameraPos: [0, 1.23, 0],
    },

    'tshirt_regular_male': {
        config: {
            front: {
                path: '/assets/t_shirt_regular_male/t_shirt_regular_male_front.gltf',
                size: {
                    width: 694.4,
                    height: 549
                }
            },
            back: {
                path: '/assets/t_shirt_regular_male/t_shirt_regular_male_back.gltf',
                size: {
                    width: 697,
                    height: 549
                }
            },
            colar: {
                path: '/assets/t_shirt_regular_male/t_shirt_regular_male_colar.gltf',
                size: {
                    width: 44,
                    height: 476.225
                }
            },
            sleeve_left: {
                path: '/assets/t_shirt_regular_male/t_shirt_regular_male_sleeve_left.gltf',
                size: {
                    width: 278.425,
                    height: 456.6
                }
            },
            sleeve_right: {
                path: '/assets/t_shirt_regular_male/t_shirt_regular_male_sleeve_right.gltf',
                size: {
                    width: 278.425,
                    height: 456.6
                }
            },
            // size_tag: {
            //     path: '/assets/t_shirt_regular_male/tshirt_regular_m_brandlogo.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // care_tag: {
            //     path: '/assets/tshirt_regular_m/t_shirt_regular_male_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        cameraPos: [0, 1.35, 0],
        avatar: male,
        pos: [0, 0, 0],
    },

    'tshirt_regular_female': {
        config: {
            front: {
                path: '/assets/t_shirt_regular_female/t_shirt_regular_female_front.gltf',
                size: {
                    width: 618,
                    height: 480.6
                }
            },
            back: {
                path: '/assets/t_shirt_regular_female/t_shirt_regular_female_back.gltf',
                size: {
                    width: 628.6,
                    height: 479
                }
            },
            colar: {
                path: '/assets/t_shirt_regular_female/t_shirt_regular_female_colar.gltf',
                size: {
                    width: 44,
                    height: 449.9
                }
            },
            sleeve_left: {
                path: '/assets/t_shirt_regular_female/t_shirt_regular_female_sleeve_left.gltf',
                size: {
                    width: 207.2,
                    height: 377.2
                }
            },
            sleeve_right: {
                path: '/assets/t_shirt_regular_female/t_shirt_regular_female_sleeve_right.gltf',
                size: {
                    width: 207.2,
                    height: 377.2
                }
            },
            // size_tag: {
            //     path: '/assets/t_shirt_regular_female/tshirt_regular_m_brandlogo.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // care_tag: {
            //     path: '/assets/tshirt_regular_m/t_shirt_regular_female_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 1.23, 0],
        avatar: female,
    },

    'tshirt_relaxed_male': {
        config: {
            front: {
                path: '/assets/t_shirt_relaxed_male/t_shirt_relaxed_male_front.gltf',
                size: {
                    width: 804.6,
                    height: 594
                }
            },
            back: {
                path: '/assets/t_shirt_relaxed_male/t_shirt_relaxed_male_back.gltf',
                size: {
                    width: 801.8,
                    height: 594
                }
            },
            colar: {
                path: '/assets/t_shirt_relaxed_male/t_shirt_relaxed_male_colar.gltf',
                size: {
                    width: 44,
                    height: 482.05
                }
            },
            sleeve_left: {
                path: '/assets/t_shirt_relaxed_male/t_shirt_relaxed_male_sleeve_left.gltf',
                size: {
                    width: 304.45,
                    height: 526.8
                }
            },
            sleeve_right: {
                path: '/assets/t_shirt_relaxed_male/t_shirt_relaxed_male_sleeve_right.gltf',
                size: {
                    width: 304.45,
                    height: 526.8
                }
            },
            // care_tag: {
            //     path: '/assets/tshirt_regular_m/t_shirt_relaxed_male_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // size_tag: {
            //     path: '/assets/t_shirt_relaxed_male/tshirt_regular_m_brandlogo.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        cameraPos: [0, 1.35, 0],
        pos: [0, 0, 0],
        avatar: male,
    },

    'tshirt_relaxed_female': {
        config: {
            front: {
                path: '/assets/t_shirt_relaxed_female/t_shirt_relaxed_female_front.gltf',
                size: {
                    width: 722.4,
                    height: 550.4
                }
            },
            back: {
                path: '/assets/t_shirt_relaxed_female/t_shirt_relaxed_female_back.gltf',
                size: {
                    width: 718.6,
                    height: 549
                }
            },
            colar: {
                path: '/assets/t_shirt_relaxed_female/t_shirt_relaxed_female_colar.gltf',
                size: {
                    width: 44,
                    height: 445.075
                }
            },
            sleeve_left: {
                path: '/assets/t_shirt_relaxed_female/t_shirt_relaxed_female_sleeve_left.gltf',
                size: {
                    width: 267.4,
                    height: 415.4
                }
            },
            sleeve_right: {
                path: '/assets/t_shirt_relaxed_female/t_shirt_relaxed_female_sleeve_right.gltf',
                size: {
                    width: 267.4,
                    height: 415.4
                }
            },
            // size_tag: {
            //     path: '/assets/t_shirt_relaxed_female/tshirt_regular_m_brandlogo.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // care_tag: {
            //     path: '/assets/tshirt_regular_m/t_shirt_relaxed_female_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 1.23, 0],
        avatar: female,
    },

    pants_regular_male: {
        config: {
            back_left: {
                path: '/assets/pants_regular_male/pants_regular_male_back_left.gltf',
                size: {
                    width: 1157.2,
                    height: 408.6
                }
            },
            back_right: {
                path: '/assets/pants_regular_male/pants_regular_male_back_right.gltf',
                size: {
                    width: 1157.2,
                    height: 408.6
                }
            },
            front_left: {
                path: '/assets/pants_regular_male/pants_regular_male_front_left.gltf',
                size: {
                    width: 1121.6,
                    height: 330.8
                }
            },
            front_right: {
                path: '/assets/pants_regular_male/pants_regular_male_front_right.gltf',
                size: {
                    width: 1121.6,
                    height: 330.8
                }
            },
            band: {
                path: '/assets/pants_regular_male/pants_regular_male_band.gltf',
                size: {
                    width: 116,
                    height: 1031.45
                }
            },
            pocket_left: {
                path: '/assets/pants_regular_male/pants_regular_male_pocket_left.gltf',
                size: {
                    width: 314.6,
                    height: 234.4
                }
            },
            pocket_right: {
                path: '/assets/pants_regular_male/pants_regular_male_pocket_right.gltf',
                size: {
                    width: 314.6,
                    height: 234.4
                }
            },
            pocket_side_left: {
                path: '/assets/pants_regular_male/pants_regular_male_pocket_side_left.gltf',
                size: {
                    width: 315.8,
                    height: 238.4
                }
            },
            pocket_side_right: {
                path: '/assets/pants_regular_male/pants_regular_male_pocket_side_right.gltf',
                size: {
                    width: 315.8,
                    height: 238.4
                }
            },
            // cuff_left: {
            //     path: '/assets/pants_regular_male/pants_regular_male_cuff_left.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // cuff_right: {
            //     path: '/assets/pants_regular_male/pants_regular_male_cuff_right.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // care_tag: {
            //     path: '/assets/pants_regular_male/pants_regular_male_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // main_label: {
            //     path: '/assets/pants_regular_male/pants_regular_male_main_label.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // size_tag: {
            //     path: '/assets/pants_regular_male/pants_regular_male_size_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        cameraPos: [0, 0.75, 0],
        pos: [0, 0, 0],
        avatar: male,
    },

    pants_regular_female: {
        config: {
            back_left: {
                path: '/assets/pants_regular_female/pants_regular_female_back_left.gltf',
                size: {
                    width: 1140.2,
                    height: 409.6
                }
            },
            back_right: {
                path: '/assets/pants_regular_female/pants_regular_female_back_right.gltf',
                size: {
                    width: 1140.2,
                    height: 409.6
                }
            },
            front_left: {
                path: '/assets/pants_regular_female/pants_regular_female_front_left.gltf',
                size: {
                    width: 1105.2,
                    height: 317.6
                }
            },
            front_right: {
                path: '/assets/pants_regular_female/pants_regular_female_front_right.gltf',
                size: {
                    width: 1105.2,
                    height: 317.6
                }
            },
            band: {
                path: '/assets/pants_regular_female/pants_regular_female_band.gltf',
                size: {
                    width: 116,
                    height: 995.85
                }
            },
            pocket_left: {
                path: '/assets/pants_regular_female/pants_regular_female_pocket_left.gltf',
                size: {
                    width: 314.6,
                    height: 213.2
                }
            },
            pocket_right: {
                path: '/assets/pants_regular_female/pants_regular_female_pocket_right.gltf',
                size: {
                    width: 314.6,
                    height: 213.2
                }
            },
            pocket_side_left: {
                path: '/assets/pants_regular_female/pants_regular_female_pocket_side_left.gltf',
                size: {
                    width: 316,
                    height: 217.6
                }
            },
            pocket_side_right: {
                path: '/assets/pants_regular_female/pants_regular_female_pocket_side_right.gltf',
                size: {
                    width: 316,
                    height: 217.6
                }
            },
            // cuff_left: {
            //     path: '/assets/pants_regular_female/pants_regular_female_cuff_left.gltf',
            //     size: {
            //         width: 0,
            //         height: 0
            //     }
            // // },
            // cuff_right: {
            //     path: '/assets/pants_regular_female/pants_regular_female_cuff_right.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // care_tag: {
            //     path: '/assets/pants_regular_female/pants_regular_female_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // main_label: {
            //     path: '/assets/pants_regular_female/pants_regular_female_main_label.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // size_tag: {
            //     path: '/assets/pants_regular_female/pants_regular_female_size_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 0.75, 0],
        avatar: female,
    },

    pants_relaxed_male: {
        config: {
            back_left: {
                path: '/assets/pants_relaxed_male/pants_relaxed_male_back_left.gltf',
                size: {
                    width: 1163.2,
                    height: 453
                }
            },
            back_right: {
                path: '/assets/pants_relaxed_male/pants_relaxed_male_back_right.gltf',
                size: {
                    width: 1163.2,
                    height: 453
                }
            },
            front_left: {
                path: '/assets/pants_relaxed_male/pants_relaxed_male_front_left.gltf',
                size: {
                    width: 1118,
                    height: 375
                }
            },
            front_right: {
                path: '/assets/pants_relaxed_male/pants_relaxed_male_front_right.gltf',
                size: {
                    width: 1118,
                    height: 375
                }
            },
            band: {
                path: '/assets/pants_relaxed_male/pants_relaxed_male_band.gltf',
                size: {
                    width: 116,
                    height: 1147.25
                }
            },
            pocket_left: {
                path: '/assets/pants_relaxed_male/pants_relaxed_male_pocket_left.gltf',
                size: {
                    width: 319.8,
                    height: 251.2
                }
            },
            pocket_right: {
                path: '/assets/pants_relaxed_male/pants_relaxed_male_pocket_right.gltf',
                size: {
                    width: 319.8,
                    height: 251.2
                }
            },
            pocket_side_left: {
                path: '/assets/pants_relaxed_male/pants_relaxed_male_pocket_side_left.gltf',
                size: {
                    width: 320.8,
                    height: 258
                }
            },
            pocket_side_right: {
                path: '/assets/pants_relaxed_male/pants_relaxed_male_pocket_side_right.gltf',
                size: {
                    width: 320.8,
                    height: 258
                }
            },
            // care_tag: {
            //     path: '/assets/pants_relaxed_male/pants_relaxed_male_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // main_label: {
            //     path: '/assets/pants_relaxed_male/pants_relaxed_male_main_label.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // size_tag: {
            //     path: '/assets/pants_relaxed_male/pants_relaxed_male_size_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 0.75, 0],
        avatar: male,
    },

    pants_relaxed_female: {
        config: {
            back_left: {
                path: '/assets/pants_relaxed_female/pants_relaxed_female_back_left.gltf',
                size: {
                    width: 1112.8,
                    height: 387.8
                }
            },
            back_right: {
                path: '/assets/pants_relaxed_female/pants_relaxed_female_back_right.gltf',
                size: {
                    width: 1112.8,
                    height: 387.8
                }
            },
            front_left: {
                path: '/assets/pants_relaxed_female/pants_relaxed_female_front_left.gltf',
                size: {
                    width: 1081.2,
                    height: 325.2
                }
            },
            front_right: {
                path: '/assets/pants_relaxed_female/pants_relaxed_female_front_right.gltf',
                size: {
                    width: 1081.2,
                    height: 325.2
                }
            },
            band: {
                path: '/assets/pants_relaxed_female/pants_relaxed_female_band.gltf',
                size: {
                    width: 116,
                    height: 1000.83
                }
            },
            pocket_left: {
                path: '/assets/pants_relaxed_female/pants_relaxed_female_pocket_left.gltf',
                size: {
                    width: 298.8,
                    height: 204.8
                }
            },
            pocket_right: {
                path: '/assets/pants_relaxed_female/pants_relaxed_female_pocket_right.gltf',
                size: {
                    width: 298.8,
                    height: 204.8
                }
            },
            pocket_side_left: {
                path: '/assets/pants_relaxed_female/pants_relaxed_female_pocket_side_left.gltf',
                size: {
                    width: 301.4,
                    height: 208
                }
            },
            pocket_side_right: {
                path: '/assets/pants_relaxed_female/pants_relaxed_female_pocket_side_right.gltf',
                size: {
                    width: 301.4,
                    height: 208
                }
            },
            // care_tag: {
            //     path: '/assets/pants_relaxed_female/pants_relaxed_female_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // main_label: {
            //     path: '/assets/pants_relaxed_female/pants_relaxed_female_main_label.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // size_tag: {
            //     path: '/assets/pants_relaxed_female/pants_relaxed_female_size_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 0.75, 0],
        avatar: female,
    },

    shorts_regular_male: {
        config: {
            back_left: {
                path: '/assets/shorts_regular_male/shorts_regular_male_back_left.gltf',
                size: {
                    width: 411,
                    height: 417.8
                }
            },
            back_right: {
                path: '/assets/shorts_regular_male/shorts_regular_male_back_right.gltf',
                size: {
                    width: 411,
                    height: 417.8
                }
            },
            front_left: {
                path: '/assets/shorts_regular_male/shorts_regular_male_front_left.gltf',
                size: {
                    width: 370.8,
                    height: 350.2
                }
            },
            front_right: {
                path: '/assets/shorts_regular_male/shorts_regular_male_front_right.gltf',
                size: {
                    width: 370.8,
                    height: 350.2
                }
            },
            band: {
                path: '/assets/shorts_regular_male/shorts_regular_male_band.gltf',
                size: {
                    width: 116,
                    height: 1045.6
                }
            },
            pocket_left: {
                path: '/assets/shorts_regular_male/shorts_regular_male_pocket_left.gltf',
                size: {
                    width: 300.2,
                    height: 231.2
                }
            },
            pocket_right: {
                path: '/assets/shorts_regular_male/shorts_regular_male_pocket_right.gltf',
                size: {
                    width: 300.2,
                    height: 231.2
                }
            },
            pocket_side_left: {
                path: '/assets/shorts_regular_male/shorts_regular_male_pocket_side_left.gltf',
                size: {
                    width: 301.2,
                    height: 234.6
                }
            },
            pocket_side_right: {
                path: '/assets/shorts_regular_male/shorts_regular_male_pocket_side_right.gltf',
                size: {
                    width: 301.2,
                    height: 234.6
                }
            },
            // care_tag: {
            //     path: '/assets/shorts_regular_male/shorts_regular_male_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // main_label: {
            //     path: '/assets/shorts_regular_male/shorts_regular_male_main_label.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // size_tag: {
            //     path: '/assets/shorts_regular_male/shorts_regular_male_size_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 0.75, 0],
        avatar: male,
    },

    shorts_regular_female: {
        config: {
            back_left: {
                path: '/assets/shorts_regular_female/shorts_regular_female_back_left.gltf',
                size: {
                    width: 414,
                    height: 361.8
                }
            },
            back_right: {
                path: '/assets/shorts_regular_female/shorts_regular_female_back_right.gltf',
                size: {
                    width: 414,
                    height: 361.8
                }
            },
            front_left: {
                path: '/assets/shorts_regular_female/shorts_regular_female_front_left.gltf',
                size: {
                    width: 386.4,
                    height: 306.2
                }
            },
            front_right: {
                path: '/assets/shorts_regular_female/shorts_regular_female_front_right.gltf',
                size: {
                    width: 386.4,
                    height: 306.2
                }
            },
            band: {
                path: '/assets/shorts_regular_female/shorts_regular_female_band.gltf',
                size: {
                    width: 116,
                    height: 920.6
                }
            },
            pocket_left: {
                path: '/assets/shorts_regular_female/shorts_regular_female_pocket_left.gltf',
                size: {
                    width: 290.2,
                    height: 199
                }
            },
            pocket_right: {
                path: '/assets/shorts_regular_female/shorts_regular_female_pocket_right.gltf',
                size: {
                    width: 290.2,
                    height: 199
                }
            },
            pocket_side_left: {
                path: '/assets/shorts_regular_female/shorts_regular_female_pocket_side_left.gltf',
                size: {
                    width: 292.2,
                    height: 199
                }
            },
            pocket_side_right: {
                path: '/assets/shorts_regular_female/shorts_regular_female_pocket_side_right.gltf',
                size: {
                    width: 292.2,
                    height: 199
                }
            },
            // care_tag: {
            //     path: '/assets/shorts_regular_female/shorts_regular_female_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // main_label: {
            //     path: '/assets/shorts_regular_female/shorts_regular_female_main_label.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // size_tag: {
            //     path: '/assets/shorts_regular_female/shorts_regular_female_size_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 0.75, 0],
        avatar: female,
    },

    shorts_relaxed_male: {
        config: {
            back_left: {
                path: '/assets/shorts_relaxed_male/shorts_relaxed_male_back_left.gltf',
                size: {
                    width: 541,
                    height: 421
                }
            },
            back_right: {
                path: '/assets/shorts_relaxed_male/shorts_relaxed_male_back_right.gltf',
                size: {
                    width: 541,
                    height: 421
                }
            },
            front_left: {
                path: '/assets/shorts_relaxed_male/shorts_relaxed_male_front_left.gltf',
                size: {
                    width: 509.2,
                    height: 344.6
                }
            },
            front_right: {
                path: '/assets/shorts_relaxed_male/shorts_relaxed_male_front_right.gltf',
                size: {
                    width: 509.2,
                    height: 344.6
                }
            },
            band: {
                path: '/assets/shorts_relaxed_male/shorts_relaxed_male_band.gltf',
                size: {
                    width: 116,
                    height: 1072.6
                }
            },
            pocket_left: {
                path: '/assets/shorts_relaxed_male/shorts_relaxed_male_pocket_left.gltf',
                size: {
                    width: 334.8,
                    height: 255.8
                }
            },
            pocket_right: {
                path: '/assets/shorts_relaxed_male/shorts_relaxed_male_pocket_right.gltf',
                size: {
                    width: 334.8,
                    height: 255.8
                }
            },
            pocket_side_left: {
                path: '/assets/shorts_relaxed_male/shorts_relaxed_male_pocket_side_left.gltf',
                size: {
                    width: 336.2,
                    height: 257.6
                }
            },
            pocket_side_right: {
                path: '/assets/shorts_relaxed_male/shorts_relaxed_male_pocket_side_right.gltf',
                size: {
                    width: 336.2,
                    height: 257.6
                }
            },
            // care_tag: {
            //     path: '/assets/shorts_relaxed_male/shorts_relaxed_male_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // main_label: {
            //     path: '/assets/shorts_relaxed_male/shorts_relaxed_male_main_label.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // size_tag: {
            //     path: '/assets/shorts_relaxed_male/shorts_relaxed_male_size_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 0.75, 0],
        avatar: male,
    },

    shorts_relaxed_female: {
        config: {
            back_left: {
                path: '/assets/shorts_relaxed_female/shorts_relaxed_female_back_left.gltf',
                size: {
                    width: 532.6,
                    height: 381.8
                }
            },
            back_right: {
                path: '/assets/shorts_relaxed_female/shorts_relaxed_female_back_right.gltf',
                size: {
                    width: 532.6,
                    height: 381.8
                }
            },
            front_left: {
                path: '/assets/shorts_relaxed_female/shorts_relaxed_female_front_left.gltf',
                size: {
                    width: 516.4,
                    height: 320
                }
            },
            front_right: {
                path: '/assets/shorts_relaxed_female/shorts_relaxed_female_front_right.gltf',
                size: {
                    width: 516.4,
                    height: 320
                }
            },
            band: {
                path: '/assets/shorts_relaxed_female/shorts_relaxed_female_band.gltf',
                size: {
                    width: 116,
                    height: 1001.6
                }
            },
            pocket_left: {
                path: '/assets/shorts_relaxed_female/shorts_relaxed_female_pocket_left.gltf',
                size: {
                    width: 295.6,
                    height: 203.9
                }
            },
            pocket_right: {
                path: '/assets/shorts_relaxed_female/shorts_relaxed_female_pocket_right.gltf',
                size: {
                    width: 295.6,
                    height: 203.9
                }
            },
            pocket_side_left: {
                path: '/assets/shorts_relaxed_female/shorts_relaxed_female_pocket_side_left.gltf',
                size: {
                    width: 298.2,
                    height: 203.9
                }
            },
            pocket_side_right: {
                path: '/assets/shorts_relaxed_female/shorts_relaxed_female_pocket_side_right.gltf',
                size: {
                    width: 298.2,
                    height: 203.9
                }
            },
            // care_tag: {
            //     path: '/assets/shorts_relaxed_female/shorts_relaxed_female_care_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // main_label: {
            //     path: '/assets/shorts_relaxed_female/shorts_relaxed_female_main_label.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
            // size_tag: {
            //     path: '/assets/shorts_relaxed_female/shorts_relaxed_female_size_tag.gltf',
                // size: {
                //     width: 0,
                //     height: 0
                // }
            // },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 0.75, 0],
        avatar: female,
    },

    extra_hanging_label: {
        config: {
            back: {
                path: '/assets/extra_hanging_label/extra_hanging_label_back.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            front: {
                path: '/assets/extra_hanging_label/extra_hanging_label_front.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            rope: {
                path: '/assets/extra_hanging_label/extra_hanging_label_rope.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            small_back: {
                path: '/assets/extra_hanging_label/extra_hanging_label_small_back.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            small_front: {
                path: '/assets/extra_hanging_label/extra_hanging_label_small_front.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
        },
        pos: [0, 0, 0], // TODO: WTF, 1.55 for debug / 0 for prod ????
        cameraPos: [0, 0, 0],
        scale: 5,
    },

    designed_box_hoodie: {
        config: {
            bottom: {
                path: '/assets/designed_box_hoodie/designed_box_hoodie_bottom.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            top: {
                path: '/assets/designed_box_hoodie/designed_box_hoodie_top.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 0, 0],
        scale: 1,
    },

    designed_box_pants: {
        config: {
            bottom: {
                path: '/assets/designed_box_pants/designed_box_pants_bottom.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            top: {
                path: '/assets/designed_box_pants/designed_box_pants_top.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 0, 0],
        scale: 1,
    },

    designed_box_shorts: {
        config: {
            bottom: {
                path: '/assets/designed_box_shorts/designed_box_shorts_bottom.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            top: {
                path: '/assets/designed_box_shorts/designed_box_shorts_top.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 0, 0],
        scale: 1,
    },

    designed_box_tshirt: {
        config: {
            bottom: {
                path: '/assets/designed_box_tshirt/designed_box_tshirt_bottom.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            top: {
                path: '/assets/designed_box_tshirt/designed_box_tshirt_top.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 0, 0],
        scale: 1,
    },

    slider_plastic_bag_hoodie: {
        config: {
            bag: {
                path: '/assets/slider_plastic_bag_hoodie/slider_plastic_bag_hoodie_bag.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            lock: {
                path: '/assets/slider_plastic_bag_hoodie/slider_plastic_bag_hoodie_lock.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            slider: {
                path: '/assets/slider_plastic_bag_hoodie/slider_plastic_bag_hoodie_slider.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 0, 0],
        scale: 1.4,
        rotation: 0.5,
        opacity: 0.7,
    },

    slider_plastic_bag_pants: {
        config: {
            bag: {
                path: '/assets/slider_plastic_bag_pants/slider_plastic_bag_pants_bag.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            lock: {
                path: '/assets/slider_plastic_bag_pants/slider_plastic_bag_pants_lock.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            slider: {
                path: '/assets/slider_plastic_bag_pants/slider_plastic_bag_pants_slider.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 0, 0],
        scale: 1.4,
        rotation: 0.5,
        opacity: 0.7,
    },

    slider_plastic_bag_shorts: {
        config: {
            bag: {
                path: '/assets/slider_plastic_bag_shorts/slider_plastic_bag_shorts_bag.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            lock: {
                path: '/assets/slider_plastic_bag_shorts/slider_plastic_bag_shorts_lock.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            slider: {
                path: '/assets/slider_plastic_bag_shorts/slider_plastic_bag_shorts_slider.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 0, 0],
        scale: 1.4,
        rotation: 0.5,
        opacity: 0.7,
    },

    slider_plastic_bag_tshirt: {
        config: {
            bag: {
                path: '/assets/slider_plastic_bag_tshirt/slider_plastic_bag_tshirt_bag.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            lock: {
                path: '/assets/slider_plastic_bag_tshirt/slider_plastic_bag_tshirt_lock.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
            slider: {
                path: '/assets/slider_plastic_bag_tshirt/slider_plastic_bag_tshirt_slider.gltf',
                size: {
                    width: 0,
                    height: 0
                }
            },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 0, 0],
        scale: 1.4,
        rotation: 0.5,
        opacity: 0.7,
    },
    shirt_regular_male: {
        config: {
            front: {
                path: '/assets/shirt_regular_male/shirt_regular_male.gltf',
                size: {
                    width: 702.2,
                    height: 631
                }
            },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 1.35, 0],
        avatar: male,
        defaultColor: '#9b9b9b'
    },

    shirt_regular_female: {
        config: {
            front: {
                path: '/assets/shirt_regular_female/shirt_regular_female.gltf',
                size: {
                    width: 702.2,
                    height: 631
                }
            },
        },
        pos: [0, 0, 0],
        avatar: female,
        cameraPos: [0, 1.23, 0],
    },

    shirt_relaxed_male: {
        config: {
            front: {
                path: '/assets/shirt_relaxed_male/shirt_relaxed_male.gltf',
                size: {
                    width: 702.2,
                    height: 631
                }
            },
        },
        cameraPos: [0, 1.35, 0],
        avatar: male,
        pos: [0, 0, 0],
    },

    shirt_relaxed_female: {
        config: {
            front: {
                path: '/assets/shirt_relaxed_female/shirt_relaxed_female.gltf',
                size: {
                    width: 702.2,
                    height: 631
                }
            },
        },
        pos: [0, 0, 0],
        avatar: female,
        cameraPos: [0, 1.23, 0],
    },
    longsleeve_regular_male: {
        config: {
            front: {
                path: '/assets/longsleeve_regular_male/longsleeve_regular_male.gltf',
                size: {
                    width: 702.2,
                    height: 631
                }
            },
        },
        pos: [0, 0, 0],
        cameraPos: [0, 1.35, 0],
        avatar: male,
        defaultColor: '#9b9b9b'
    },

    longsleeve_regular_female: {
        config: {
            front: {
                path: '/assets/longsleeve_regular_female/longsleeve_regular_female.gltf',
                size: {
                    width: 702.2,
                    height: 631
                }
            },
        },
        pos: [0, 0, 0],
        avatar: female,
        cameraPos: [0, 1.23, 0],
    },

    longsleeve_relaxed_male: {
        config: {
            front: {
                path: '/assets/longsleeve_relaxed_male/longsleeve_relaxed_male.gltf',
                size: {
                    width: 702.2,
                    height: 631
                }
            },
        },
        cameraPos: [0, 1.35, 0],
        avatar: male,
        pos: [0, 0, 0],
    },

    longsleeve_relaxed_female: {
        config: {
            front: {
                path: '/assets/longsleeve_relaxed_female/longsleeve_relaxed_female.gltf',
                size: {
                    width: 702.2,
                    height: 631
                }
            },
        },
        pos: [0, 0, 0],
        avatar: female,
        cameraPos: [0, 1.23, 0],
    },
}

export default itemsConfig
