
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

import * as THREE from 'three'

function hexTo0xRRGGBB(hexColor) {
    const red = parseInt(hexColor.substring(1, 3), 16)
    const green = parseInt(hexColor.substring(3, 5), 16)
    const blue = parseInt(hexColor.substring(5), 16)

    return (red << 16) | (green << 8) | blue
}
class Item {
    constructor({ editor, config, pos = [0, 0, 0], scale = 1, name, opacity = 1, rotation = 0, cameraPos = new THREE.Vector3(0,0,0) }) {
        this.name = name
        this.config = config
        this.pos = pos
        this.cameraPos = cameraPos
        this.scale = scale
        this.rotation = rotation
        this.opacity = opacity
        this.editor = editor
        this.objects = []
        this.group = null
        this.loader = new GLTFLoader()
    }

    async load(data) {
        this.objects = []
        this.group = null
        this.group = new THREE.Group()

        const keys = Object.keys(this.config)

        for (let i = 0; i < keys.length; i++) {
            const el = keys[i]
            await this._loadHandler(this.config[el].path, data.parts[el], el)
        }

        this.objects.forEach((el) => {
            this.group.add(el)
        })

        this.editor.addObject(this.group)
        await this.updateElements()

        return this.objects
    }

    async updateElements() {
        return new Promise(async (resolve, reject) => {
            for (let index = 0; index < this.objects.length; index++) {
                const element = this.objects[index]

                window.drawingContext.clearRect(0, 0, 512, 512)
                window.drawingContext.fillStyle = element.params.color.hex
                window.drawingContext.fillRect(0, 0, 512, 512)

                element.material.map = new THREE.CanvasTexture(window.drawingCanvas)
                element.material.map.needsUpdate = true

                await new Promise(resolve => setTimeout(resolve, 100));
            }
            resolve()
        })
    }

    async update(data) {
        return new Promise(async (resolve, reject) => {
            for (let index = 0; index < this.objects.length; index++) {
                const element = this.objects[index]
                if (data.parts[element.name]) {
                    element.params.color = data.parts[element.name].color

                    // window.drawingContext.clearRect(0, 0, 512, 512)
                    // window.drawingContext.fillStyle = data.parts[element.name].color
                    // window.drawingContext.fillRect(0, 0, 512, 512)

                    // if (element.params.prints) {
                    //     for (let index = 0; index < element.params.prints.length; index++) {
                    //         const element2 = element.params.prints[index];

                    //         window.drawingContext.save()

                    //         window.drawingContext.translate(element2.pos.x + 512, element2.pos.y + 512)

                    //         window.drawingContext.rotate(element2.rotation * Math.PI / 180)

                    //         window.drawingContext.scale(element2.scale, element2.scale)

                    //         window.drawingContext.drawImage(element2.img, -512, -512, 512, 512)
                    //     }

                    //     window.drawingContext.restore()
                    // }

                    // element.material.map = new THREE.CanvasTexture(window.drawingCanvas)
                    // element.material.map.needsUpdate = true

                    // await new Promise(resolve => setTimeout(resolve, 100));

                }
            }
            resolve()
        })
    }

    rotate(deg) {
        this.group.rotateY(Math.PI / 180 * deg)
    }

    async forceUpdate(data) {
        return new Promise(async (resolve, reject) => {
            for (let index = 0; index < this.objects.length; index++) {
                const element = this.objects[index]
                if (data.parts[element.name]) {
                    window.drawingContext.clearRect(0, 0, 512, 512)
                    window.drawingContext.fillStyle = data.parts[element.name].color
                    window.drawingContext.fillRect(0, 0, 512, 512)

                    if (element.params.prints) {
                        for (let index = 0; index < element.params.prints.length; index++) {
                            const element2 = element.params.prints[index];

                            window.drawingContext.save()

                            window.drawingContext.translate(element2.pos.x + 512, element2.pos.y + 512)

                            window.drawingContext.rotate(element2.rotation * Math.PI / 180)

                            window.drawingContext.scale(element2.scale, element2.scale)

                            window.drawingContext.drawImage(element2.img, -512, -512, 512, 512)
                        }

                        window.drawingContext.restore()
                    }

                    element.material.map = new THREE.CanvasTexture(window.drawingCanvas)
                    element.material.map.needsUpdate = true

                    await new Promise(resolve => setTimeout(resolve, 100));

                }
            }
            resolve()
        })
    }

    async unload() {
        this.objects.forEach((el) => {
            el.geometry.dispose()
            el.material.dispose()
            el.clear()
        })
        this.editor.scene.remove(this.group)
    }

    show() {
        this.group.visible = true
    }

    hide() {
        this.group.visible = false
    }

    updateElement() {
        // this.objects.forEach((el) => {
        //     console.log(el)
        // })
    }

    async _loadHandler(el, params, key) {
        return new Promise(async (resolve, reject) => {
            await this.loader.load(el, async (gltf) => {
                const object = gltf.scene


                let textureSize = { width: 0, height: 0 };
                let uvBounds = { minU: Infinity, minV: Infinity, maxU: -Infinity, maxV: -Infinity };

                object.traverse((innerEl) => {
                    if (innerEl.isMesh) {
                        innerEl.name = key
                        innerEl.isItem = true;

                        if (innerEl.geometry.attributes.uv) {
                            const uv = innerEl.geometry.attributes.uv;
                            for (let i = 0; i < uv.count; i++) {
                                const u = uv.getX(i);
                                const v = uv.getY(i);
                                uvBounds.minU = Math.min(uvBounds.minU, u);
                                uvBounds.minV = Math.min(uvBounds.minV, v);
                                uvBounds.maxU = Math.max(uvBounds.maxU, u);
                                uvBounds.maxV = Math.max(uvBounds.maxV, v);
                            }
                        }

                        innerEl.position.set(this.pos[0], this.pos[1], this.pos[2])
                        innerEl.scale.set(this.scale, this.scale, this.scale)

                        const radians = this.rotation * 2 * Math.PI;
                        innerEl.rotation.y = radians;

                        this.objects.push(innerEl)
                        innerEl.params = params ?? {}
                        if (!innerEl.params.color) {
                            innerEl.params.color ={ 
                                pantone: "PT-1238421C",
                                hex :"#ffffff"
                            }
                        }
                        // else 
                        // {

                        //     if (!innerEl.params.color.hex) {
                        //         let color = { 
                        //             pantone: "PT-1238421C",
                        //             hex :"#ffffff"
                        //         }
    
                        //         color.hex = innerEl.params.color
                        //         innerEl.params.color = color
                        //     }
                        // }

                        

                        try {

                            if (params != undefined) {
                                let prints = params.prints

                                if (prints != undefined) {
                                    for (let index = 0; index < prints.length; index++) {
                                        const print = prints[index];

                                        if (print != undefined && print.rotation != undefined) {

                                            if (print.scale != undefined && print.scale < 1) {
                                                let tempScale = 1 / this.editor.calculateRotatedSquareScale(print.rotation)
                                                print.scale = print.scale * tempScale
                                            }

                                            if (print.rotation != 0) {
                                                print.rotation = print.rotation - 90;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        catch (error) {
                        }


                        innerEl.params.uvBounds = uvBounds

                        if (innerEl.material) {
                            if (this.opacity != 1) {
                                // innerEl.material.side = THREE.DoubleSide;
                                innerEl.material.roughness = 0;
                                innerEl.material.metalness = 0.4;
                                innerEl.material.specular = 0.9;
                                innerEl.material.transparent = true;
                                innerEl.material.opacity = this.opacity;
                            } else {
                                // innerEl.material.side = THREE.DoubleSide;
                                innerEl.material.roughness = 1;
                                innerEl.material.metalness = 0;
                                innerEl.material.specular = 1;
                                innerEl.material.envMapIntensity = 0.1;
                            }

                            const newColor = new THREE.Color(hexTo0xRRGGBB('#ffffff'))
                            innerEl.material.color.set(newColor);

                            const textureData = new Uint8Array([newColor.r * 255, newColor.g * 255, newColor.b * 255])
                            const texture = new THREE.DataTexture(textureData, 1, 1, THREE.RGBFormat)
                            texture.needsUpdate = true;


                            innerEl.material.map = texture;
                            innerEl.material.needsUpdate = true;
                        }

                    }
                })

                resolve()
            })
        })
    }
}

export default Item
